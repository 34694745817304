@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
}

html {
    min-height: 100%;
    position: relative;
}

body {
    height: 100%;
}